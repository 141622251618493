import { getDomainLocale } from "@/url";

//
// field${language_suffix} --- использование
//
export const language_suffix =
  getDomainLocale() === "localhost"
    ? ""
    : getDomainLocale() === "com"
      ? ""
      : "_" + getDomainLocale();
export const gqlDateFormat = "MM-DD-YYYY";

export const getTranslatedField = (object, field_name) => {
  return object ? object[field_name] || object[field_name + "_default"] : null;
};
