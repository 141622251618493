export default {
  items_view: {
    header: 'Produkty',
    filter_form: {
      name_placeholder: 'Nazwa',
      category_placeholder: 'Kategoria',
      parlor_placeholder: 'Salon',
      all_categories: 'Wszystkie kategorie',
      all_parlors: 'Wszystkie salony',
    },
    load_more_button: 'Załaduj więcej',
    not_found_message: 'Nie znaleziono',
  },
  item_view: {
    error_message: 'Błąd ładowania danych',
    available_in_parlor: 'Dostępne w twoim salonie',
    not_available_in_parlor: 'Niedostępne w twoim salonie',
    similar_items_label: 'Podobne produkty',
    log_in_to_buy: 'Zaloguj się, aby kupić',
  },
  item_simple_card: {
    studio_price_label: 'Cena w studiu',
    shop_price_label: 'Cena w sklepie',
  },
  student_candidate: {
    form: {
      who_studing: 'Student lub wynajem miejsca',
      phone_number: 'Telefon',
      first_name: 'Imię',
      last_name: 'Nazwisko',
      social_link: 'Link do dowolnej sieci społecznościowej',
      parlors: 'Salon',
      find_out: 'Skąd dowiedziałeś się o nas',
      start_date: 'Pożądana data rozpoczęcia',
      types: 'Typy sesji',
      work_permit_document: 'Pozwolenie na pracę',
      sanitary_book: 'Książeczka sanitarna',
      identity_proof: 'Dowód tożsamości',
      work_photos: 'Zdjęcia prac',
      end_date: 'Pożądana data zakończenia',
    },
  },
  user: {
    referral_links: {
      booking: 'Rezerwacja sesji',
      student_candidate: 'Student i wynajem',
      store: 'Sklep klienta',
      candidates_form: 'Zaproś pracownika',
    },
  },
  products: {
    want_add: 'Chcę dodać wybrane produkty pielęgnacyjne do kosztu sesji',
    no_warranty: '*bez tej opcji nie możemy zagwarantować 100% skutecznego gojenia',
  },
  booking: {
    fast_booking: 'Szybka rezerwacja',
    fast_booking_warning: 'Rezerwacja nie jest ostateczna, nasz menedżer skontaktuje się z Tobą w celu potwierdzenia godziny i daty sesji',
    phone_annotate: 'Aby nasz zespół sprzedaży mógł szybciej reagować, prosimy o podanie lokalnego numeru kontaktowego. Dziękujemy!',
    increased_risk: 'Uwaga! Sesja wysokiego ryzyka',
    approximate_price: 'Orientacyjna cena',
    price_warning: 'Dodatkowe przedmioty nie są uwzględnione',
    type: {
      info: 'Wygodny formularz rezerwacji sesji ze wszystkimi niezbędnymi informacjami',
      booking_button: 'ZAREZERWUJ SESJĘ',
      unsure: 'Nadal nie możesz się zdecydować? Pomożemy Ci!',
      consultant_help: 'Nasi konsultanci chętnie ci pomogą',
      consultation_button: 'KONSULTACJA',
    },
    color: {
      black: 'Czarny',
      colored: 'Kolorowy',
    },
    controls: {
      next: 'DALEJ',
      back: 'Wstecz',
      no_select: 'Nie wybrano',
    },
    place_service: {
      select_studio: 'Wybierz studio',
      service: 'Usługa',
      size: 'Rozmiar',
      location: 'Lokalizacja',
    },
    customer_info: {
      login_prompt: 'Aby kontynuować, zaloguj się na swoje konto',
      login_button: 'Zaloguj się',
      black_white_colored: 'Czarne i białe lub kolorowe?',
      select_style: 'Wybierz styl',
      tell_more: 'Opowiedz nam więcej o swoich preferencjach dotyczących tatuażu i podziel się przykładami (referencjami)',
      login_promo: 'Zaloguj się i otrzymaj 10% zniżki',
      wish: 'Życzenie',
    },
    date_time: {
      choose_time: 'Wybierz czas wizyty',
    },
    master_card: {
      master_unavailable: 'Mistrz nie jest dostępny w tym czasie',
      master_guest_warning: 'UWAGA! Mistrz jest gościem',
      rank: 'Ranga:',
      works_count: 'Liczba prac:',
      book_now: 'Zarezerwuj teraz',
      select_another_time: 'Wybierz inny czas',
    },
    success: {
      session: 'SESJA',
      become_mystery_shopper: 'Zostań tajemniczym klientem',
      add_to_calendar: 'Dodaj wydarzenie do kalendarza',
      call_on_day: '*w dniu sesji administrator salonu zadzwoni do Ciebie',
      return_to_menu: 'Wróć do menu',
      recommended_products: 'Rekomendowane produkty do leczenia',
    },
  },
  new_landing: {
    count_block: {
      countries: 'Liczba krajów',
      studios: 'Studiów',
      awards: 'Nagrody',
      masters: 'Mistrzowie',
    },
    header_banner: {
      label_1: 'Nie owijamy w bawełnę',
      label_2: 'Robimy tatuaże!',
      book_now: 'Zapisz się',
      consultation: 'Konsultacja',
      prices: 'Cennik',
      top_works: 'Top prace',
      advantages: 'Zalety',
      change_parlor: 'Zmień salon',
    },
    course_card: {
      theory_part: 'Część teoretyczna:',
      practice_part: 'Część praktyczna:',
      advance: 'Dodatkowe zalety:',
    },
    helpful_links: {
      for_masters: 'Dla mistrzów',
      tattoo_news: 'Aktualności o tatuażu',
      tattoo_news_description:
        'Bądź na bieżąco z najważniejszymi wydarzeniami ze świata tatuażu',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'Otrzymuj porady i wsparcie od naszej społeczności tatuatorskiej oraz ekspertów tej branży',
      rent_space: 'Wynajem miejsca',
      rent_space_description:
        'Dowiedz się o możliwościach współpracy na warunkach wynajmu miejsca pracy w studiach VEAN',
      tattoo_promotions: 'Promocje i zniżki',
      tattoo_certificates: 'Bony podarunkowe',
      consumables: 'Materiały eksploatacyjne',
      equipment: 'Sprzęt',
    },
    price_block: {
      title: 'Cennik',
      loyalty_program: {
        title: 'Program lojalnościowy',
      },
      actions: {
        book_now: 'Zarezerwuj teraz',
        calculate_cost: 'Oblicz koszt',
      },
      // другие вложенные переводы...
    },
    top_works: {
      title: 'Najlepsze prace',
      from_the_world: 'z całego świata',
    },
    why_vean: {
      title: 'Dlaczego VEAN',
      individual_approach: 'Indywidualne podejście',
      trust: 'Zaufanie',
      safety_guarantee: 'Gwarancja bezpieczeństwa',
      convenient_location: 'Dogodna lokalizacja',
      visited_studio: 'Ludzi odwiedziło to studio',
    },
  },
  mystery_shopper: {
    title: 'Tajemniczy klient',
    description:
      'Tajemniczy klient to osoba, która sprawdza pracę salonu pod pozorem zwykłego klienta. Ocenia poziom obsługi w salonie.',
    offer:
      'Jeśli zdecydujesz się zostać tajemniczym klientem na tę sesję - podarujemy Ci monety',
    instructionTitle: 'Instrukcja',
    instructionDetails:
      'W trakcie sesji będziesz musiał ocenić salon i personel pod różnymi kryteriami. Po sesji otrzymasz SMS z linkiem do ankiety, którą będziesz musiał wypełnić (tylko uczciwie!)',
    question: 'Czy chcesz zostać tajemniczym klientem?',
    yesButton: 'Tak',
    noButton: 'Nie, dziękuję',
    successMessage:
      'Będziesz musiał ocenić studio pod kątem następujących parametrów:',
    criteria1: 'Konsultacja telefoniczna i w mediach społecznościowych',
    criteria2: 'Konsultacja w studio przy przybyciu',
    criteria3: 'Polityka cenowa studia',
    criteria4: 'Ekskterier: Fasada studia, szyldy, lokalizacja itp.',
    criteria5: 'Wnętrze studia, atmosfera',
    criteria6:
      'Asortyment produktów, upominków i artykułów do pielęgnacji (witryna)',
    criteria7: 'Atmosfera: Telewizja, muzyka, personel',
    criteria8:
      'Dostępność przekąsek, rozrywki, czasopisma i katalogi ze szkicami',
    criteria9: 'Kontakt z mistrzem',
    criteria10: 'Kontakt z administratorem',
  },
  cookies: {
    text: `Używamy plików cookie, aby poprawić korzystanie z naszej witryny, 
      wyświetlać spersonalizowane reklamy lub treści 
      oraz analizować nasz ruch. Klikając „Akceptuj”, 
      zgadzasz się na korzystanie z plików cookie.`,
    confirm: 'Akceptuj',
    more: 'Więcej',
    manage: 'Zarządzaj',
    config: {
      necessary: 'Niezbędne',
      preferences: 'Preferencje',
      statistics: 'Statystyczne',
      marketing: 'Marketingowe',
    },
  },
  header_menu: {
    logout: 'Wyjście',
    main: 'Strona główna',
    about_us: 'O nas',
    discount_page: 'Rabaty',
    loyalty_page: 'Program lojalnościowy',
    ai_page_link: 'Generuj szkic',
    fonts_page_link: 'Czcionki tatuażu',
    confidence: 'Polityka prywatności',
    quality_certificate: 'Certyfikaty jakości',
    complaints: 'Skargi i sugestie',
    public_offer_link: 'Oferta publiczna',
    work: 'Chcę pracować w VEAN',
    dockuments_page_link: 'Dokumenty',
    login: 'Zaloguj się',
    account: 'Profil',
  },
  post: {
    welcome: 'Cześć. Byłeś u nas na  sesji',
    no_answer_questions: 'Nie odpowiedziałeś na następujące pytania:',
    point: 'Proszę oceń jakość obsługi i odbierz prezent!',
    send: 'Wysłać',
    error: 'Wystąpił błąd!',
    not_found: 'Sesja nieodnaleziona!',
    not_call: 'Nie trzeba do mnie oddzwaniać',
    thank: 'Dziękujemy bardzo za ocenę!',
    bot: 'Po 21 dniach nasz bot zadzwoni do Ciebie z nieznanego numeru w celu wyjaśnienia informacji na temat świadczonej usługi i  pielęgnacji.',
    certificate:
      'Wygenerowano dla Ciebie promocyjny certyfikat na %{gift_price} VeanCoins',
    social_networks:
      'Prosimy o pozostawienie opinii o nas w sieciach społecznościowych',
    month:
      'Użyj go w ciągu miesiąca, nie zapomnij przypomnieć o tym administratorowi!',
    dissatisfied:
      'Jesteś niezadowolony/a z usługi? Rozpatrzymy twoj wniosek tak szybko, jak to jest możliwe.',
    scores: {
      administrator: 'Moja ocena administratora studia:',
      master: 'Moja ocena tatuatora studia:',
      recommendations:
        'Udzielono mi ustnych i pisemnych porad dotyczących pielęgnacji:',
      care: 'Zaproponowano mi wykupienie profesjonalnej opieki:',
      sketch: 'Zaproponowano mi zamówienie indywidualnego szkicu/projektu:',
      souvenir: 'Zaproponowano mi zakup pamiątek:',
      master_contact:
        'Czy mistrz zostawił ci swoje osobiste dane kontaktowe (osobisty numer telefonu, link do osobistych mediów społecznościowych), czy też ty zostawiłeś mistrzowi swoje własne?',
      repeat_record: 'Czy chcesz powtórzyć zapis?',
    },
    scores_mystery_shopper: {
      instagram: 'ocena naszego instarama',
      site: 'Ocena strony internetowej vean-tattoo.%{locale}',
      phone_consultation:
        'Konsultacja przez telefon i w sieciach społecznościowych',
      consultation: 'Konsultacja w studio przy wizycie',
      price: 'Polityka cenowa studia Twoim zdaniem',
      exterior: 'Na zewnątrz: fasada studia, oznakowanie, lokalizacja itp.',
      interior: 'Wnętrze studia, wyposażenie',
      assortment: 'Asortyment pamiątek i produktów pielęgnacyjnych (gablota)',
      atmosphere: 'Atmosfera: Telewizja, muzyka, zespół',
      leisure:
        'Dostępność napojów, przekąsek , zajęć rekreacyjnych, gazet(czasopism) i katalogów szkicowych',
      master: 'Komunikacja z mistrzem',
      admin: 'Komunikacja z menadzerem',
      master_one_place: 'Mistrz pracuje tylko w tym studio (Tak/nie)',
      master_contact:
        'Poproś o podanie danych kontaktowych mistrza. Dane zostały podane? (Tak/nie)',
      care: 'Czy zaproponowano Ci zakup produktów pielęgnacyjnych  i naszych produktów pamiątkowych, czy otrzymałeś drobny upominek: magnes, naklejkę lub certyfikat na 10% kwoty wykonanej pracy na następną usługę? (tak/nie)',
      conclusion: 'Końcowa ocena kontroli',
    },
    inputs: {
      cost: 'zapłaciłem/am za pracę:',
      cost_other:
        'zakupiłem/am również biżuterię/produkty pielęgnacyjne na kwotę:',
      review: 'Nasze niedociągnięcia zauważone podczas pracy:',
      photo: 'Фото салона',
    },
    inputs_mystery_shopper: {
      recommendation: 'Wasze rekomendacje dla nas',
      why_vean: 'Dlaczego wybrali Państwo VeAn?',
    },
  },
  header: {
    online_appointment: 'Rejestracja online',
    post_session: 'Оcena jakości',
    get_price: 'Konsultacja',
    consultation: '(Konsultacja)',
    order_consultation: 'Zamów konsultację',
  },
  index: {
    parlor: 'Studio',
    master: 'Tatuator',
    session_type: 'Rodzaj usługi',
    session_location: 'Miejsce świadczenia usług',
    datetime: 'Data i godzina',
    create_appointment: 'Rejestracja',
    bonus: '*bonus %{customer_bonus} VeanCoins za samodzielny zapis studio ',
    sketch: 'Szkic (Opcjonalnie)',
  },
  session_types: {
    tattoo: 'Tatuaż',
    tatouage: 'Makijaż permanentny',
    piercing: 'Piercing',
    stroke: 'Usunięcie tatuażu',
    training_tattoo: 'Kurs tatuażu',
    training_piercing: 'Kurs piercingu',
    training_tattouage: 'Kurs tatuażu',
    training_udder: 'Kurs usuwania tatuażu',
  },
  price_form: {
    client_name: 'Imię',
    client_phone: 'telefon',
    client_comm: 'Twoja usługa',
    sketch_desc: 'Dodaj szkic ( * Opcjonalnie )',
    sketch_button: 'Wybierz plik',
    send: 'Wyślij',
    or: 'Albo',
    country: 'Kraj',
    city: 'Miasto',
    comment: 'Komentarz',
    val_error: 'sprawdź wprowadzone dane!',
    ths_message: 'Dziękuję! Wkrótce skontaktujemy się z Tobą',
    go_back: 'powrót',
    file_size: 'Rozmiar pliku nie powinien przekraczać 5 MB!',
  },
  selectors: {
    parlor: {
      placeholder: 'Szukaj po nazwie salonu...',
    },
    master: {
      placeholder: 'Szukaj po imieniu, usłudze...',
    },
    select_type: {
      select: 'Wybierz',
      next: 'Następny',
      no_price: 'Umowne',
      ad: 'z',
      da: 'do',
      selected: 'selected',
      info: 'Cena może zależeć od rozmiaru i lokalizacji',
    },
    datetime: {
      select_date: 'Wybierz interesujący Cię termin',
      no_active_times: 'Nie ma już wolnych miejsc na tę datę. Wybierz inną!',
    },
  },
  verify: {
    verify_account: 'Zweryfikuj konto',
    type: 'Metoda weryfikacji',
    check_code: 'Sprawdź kod',
    status: 'Status',
    input_phone: 'Wpisz numer telefonu',
    email_help: 'Możesz również użyć poczty elektronicznej do weryfikacji',
    sms_sent: 'Twój kod został wysłany SMS-em, wpisz go poniżej',
    resend_sms_after: 'Ponowne przesłanie dostępne za %{seconds} sekund',
    resend_sms: 'Wyślij ponownie',
    buttons: {
      back: 'Powrót',
      next: 'Dalej',
      create_appointment: 'Zapisz się',
    },
  },
  success: {
    h1: 'Dziękujemy, Sesja zarezerwowana',
    h2: 'Po zatwierdzeniu rejestracji przez administratora zostanie do Ciebie wysłany SMS.',
    mystery: 'Zostać tajemniczym klientem',
  },
  about: {
    header: 'Informacjа o Tobie',
    birth_info:
      'Zakładamy, że jesteś pełnoletni , Twoj wiek również jest potrzebny do zniżek urodzinowych :)',
    inputs: {
      name: 'Imię i nazwisko',
      find_out: 'Skąd się dowiedziałeś',
      birth_date: 'Data urodzenia',
    },
    buttons: {
      skip: 'Pominąć',
      back: 'Powrót',
      next: 'Dalej',
    },
  },
  footer: {
    header: 'Obserwuj nas na mediach społecznych, żeby być na bieżąco',
  },
  subscribe_modal: {
    header: 'Subskrybuj wiadomości za bonus',
    submit: 'Wyślij',
    wrong_data: 'Sprawdź poprawność wprowadzonych danych!',
    wrong_email: 'Nieprawidłowy adres e-mail!',
    thanks: 'Dziękujemy za zapisanie się!',
    error: 'Wystąpił błąd!',
  },
  chat_bot: {
    call: 'Zadzwonić',
    online_record: 'Online zapis',
    online_consultation: 'Online konsultacja',
    need_help: 'Potrzebujesz pomocy ze szkicem?',
    order_sketch: 'Zamów szkic',
    dress_sketch: 'Przymierz szkic',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Sytuacje problemowe',
      id: 'Numer',
      status: 'Status',
    },
    reset_password: {
      title: 'Zapomniałeś hasła',
      submit: 'wyślij',
      forgot_password: 'Zapomniałeś hasła',
      phone: 'Wprowadź numer telefonu',
      success_message: 'Tymczasowe hasło zostało wysłane na twój email/telefon',
    },
    registration: {
      name: 'Imię',
      surname: 'Nazwisko',
      registered: 'Rejestracja',
      congratulations: 'Gratulacje!',
      successfully_registered: 'Zarejestrowałeś się pomyślnie!',
      log_in: 'Zaloguj się',
    },
    options: {
      no_interest_in_promotions: 'Nie interesują mnie promocje i bonusy',
      dislike_frequent_sms: 'Nie lubię częstych wiadomości SMS',
      already_have_tattoo:
        'Mam już tatuaż i nie planuję wkrótce robić kolejnego',
      other: 'Inne',
    },
    login_page: {
      log_in_page: 'Wejście do konta osobistego',
      number_missing: 'Nie masz numeru?',
      register: 'Zarejestruj się!',
      steps: {
        sign_up: 'Zarejestruj się w programie lojalnościowym lub ZALOGUJ SIĘ',
        check_coins: 'Sprawdź swoje VeanCoin, rejestrując się w programie lojalnościowym',
        get_coins: 'Otrzymuj VeanCoins na koniec każdej sesji',
        accumulate: 'Zbieraj i gromadź VeanCoin w swoim profilu',
        book_session: 'Umów się na sesję i przyjdź do Vean, aby zrobić tatuaż',
        exchange_coins: 'Wymień VeanCoins i otrzymuj rabaty',
      },
    },
    find_customer: {
      continue: 'Kontynuuj',
      not_found: 'Klient nie został znaleziony!',
      select_type: 'Wybierz typ logowania',
      no_password: 'Nie ustawiłeś hasła!',
      found: 'Znaleziono!',
      check: 'Sprawdzanie...',
      invalid_phone_or_password: 'Nieprawidłowe dane logowania',
      error_login: 'Błąd logowania',
      phone: 'Telefon',
    },
    product_card: {
      availability: 'Dostępność w studiach:',
      unavailable: 'Niedostępne',
    },
    store_body: {
      full_points_products: 'Towary które można kupić za VeanCoins',
      half_points_products: 'Towary które można kupić ze zniżką 50%',
      no_products: 'Brak towarów',
    },
    description_block: {
      loyalty_system: 'System lojalności VeAn',
      show_more: 'Pokaż więcej',
    },
    profile_card: {
      my_sketches: 'Moje szkice',
      share_link: 'Udostępnij ten link',
      tips: 'Napiwek',
      send_certificate: 'Przesłać certyfikat',
      vip: 'VIP',
      not_vip: 'Zostań VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Pieniądze',
      active_certificates: 'Aktywowane VeanCoins',
      balance: 'Bilans',
      sessions: 'Sesje',
      verify_account_status: 'Status konta',
      security_percent: 'Bezpieczeństwo:',
      points_alltime: 'Zdobyte VeanCoins przez cały czas',
      available_certificates: 'Dostępne certyfikaty',
      certificates: 'Certyfikaty pieniężne',
      bought_certificates: 'Aktywowane VeanCoins',
      certificate_number: 'Numer certyfikatu',
      available_for: 'Ważny do: ',
      no_active_certificates: 'Brak certyfikatów pieniężnych',
      no_active_bought_certificates: 'Brak aktywnych VeanCoins',
      coming_sessions: 'Nadchodzące sesje',
      complete_agreement: 'Wypełnij umowę',
      date: 'Data: ',
      you_was: 'Byłeś na %{sessions} sesjach',
      edit: 'Edycja',
      not_email: 'aby wysłać certyfikat, musisz wypełnić e-mail',
      exist_application: 'jest aplikacja',
      need_exist_application: 'brak aplikacji',
      need_confirm_account: 'Potrzebne potwierdzenie konta',
      verified: 'Zweryfikowany',
      choose_payment_method: 'Wybierz sposób płatności',
      parlor: 'Salon',
      sign_up_online: 'Zapisz się na sesję',
      mystery_shopper: 'Tajemniczy klient',
      sign_up_secret_buyer:
        'Zapisz się u nas na sesję i zostań tajemniczym klientem',
      pay: 'Zapłać',
      prepayment: 'Zaliczka',
      questionnaire: 'Ankieta',
      agreement: 'Umowa',
      fill_required_information: 'Należy wypełnić informacje',
      buy: 'Uzupełnij saldo',
      archive: 'Archiwum',
      support_service: 'Obsługa klienta Vean Tattoo Community',
      balance_buy_history: 'Historia zakupionych VeanCoins',
      balance_history: 'Historia uzyskanych VeanCoins',
      buy_history: 'Historia zakupów',
      сost: 'Koszt',
      payment_link: 'Link do płatności',
      attributes: 'Atrybuty',
      invite_friend: 'Zaproś znajomego',
      phone_or_email: 'Wprowadź numer telefonu lub adres email',
      report_problem: 'Zgłoś problem',
      note: 'Wprowadź komentarz',
      tooltip_coins:
        'musisz aktywować swoje zgromadzone VeanCoins, aby uniknąć ich spalenia w ciągu 1 roku dla wybranej przez siebie usługi lub zakupu towarów',
      tooltip_certificate:
        'ilość Twoich zakupionych lub otrzymanych w prezencie certyfikatów pieniężnych, które można wykorzystać w ciągu 2 miesięcy',
      tooltip_сonfirmed:
        'W edycji zmień «ФИО или username» na «Imię i Nazwisko»',
      tooltip_missing:
        'Dodaj informacje o sobie (imię i nazwisko, datę urodzenia, link do Twoich mediów społecznościowych, skąd dowiedziałeś się o nas, numer telefonu i email do potwierdzenia tożsamości)',
      tooltip_verified:
        'Musisz potwierdzić swoje konto za pomocą numeru telefonu lub email, aby chronić zdobyte i zgromadzone przez Ciebie VeanCoins.',
      tooltip_vip:
        'Klient VIP - to status, który może uzyskać każdy klient, który wyda na usługi więcej niż 1500 euro. Status VIP daje pewne korzyści dla jego właściciela, takie jak np. szybki priorytetowy zapis na sesje bez kolejki, dostęp do unikalnych towarów i usług, po osiągnięciu statusu VIP klient od razu otrzymuje naliczenie 1000 VeanCoins na swoim koncie',
    },
    profile_edit: {
      additional_information: 'Dodatkowe informacje na temat VeanCoins',
      parlors: 'Salon (który jest bliższy lub główny)',
      client_card: 'Karta klienta',
      full_name: 'Imię i nazwisko lub nazwa użytkownika',
      birth_date: 'Data urodzenia (do gratulacji)',
      social_media_link: 'Link do mediów społecznościowych',
      find_out: 'Skąd się dowiedziałeś',
      or: 'lub',
      phone_placeholder: 'Telefon',
      save: 'Zapisz',
      password_tooltip:
        'Utwórz hasło, aby w przyszłości mieć dostęp do swojego profilu bez pomocy SMS-ów i wiadomości e-mail. Zabezpieczysz również swoje dane osobowe.',
      password: 'Hasło',
      repeat_password: 'Powtórz hasło',
      email_taken: 'Ten adres e-mail jest już zajęty!',
      phone_taken: 'Ten numer telefonu jest już zajęty!',
      simple_password: 'Zbyt proste hasło!',
      invalid_password: 'Nieprawidłowe hasło!',
      password_for_application: 'Hasło do aplikacji (nieobowiązkowe)',
      congratulate: 'Gratulacje!',
      successfully_registered: 'Pomyślnie zarejestrowano!',
      need_confirm_account: 'Potrzebna weryfikacja konta',
      yes: 'Tak',
      no: 'Nie',
      phone_confirmed: 'Telefon potwierdzony',
      email_confirmed: 'E-mail potwierdzony',
      change_password: 'Zmień hasło',
      old_password: 'Stare hasło',
      new_password: 'Nowe hasło',
      confirm_password: 'Powtórz hasło',
      invalid_phone_or_password: 'Nieprawidłowy numer telefonu lub hasło',
      error_login: 'Błąd logowania',

      years_old: 'Wiek',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Płeć',
      man: 'Mężczyzna',
      woman: 'Kobieta',
      gender_hidden: 'Wolę nie podawać',
      language: 'Język',
      preferred_style_status: 'Preferowane style',
      classic: 'Klasyczny / realizm',
      freak: 'Freak / Trash / Anime',
      fashion: 'Moda / Sztuka / Minimalizm',
      rock: 'Rock / Hard',
      pop: 'Pop / Mas',
      accommodation_status: 'Status zakwaterowania',
      local: 'Miejscowy',
      tourist: 'Turysta',
      guest: 'Gość',
      lifestyle_status: 'Styl życia',
      extreme: 'Ekstremalny',
      dance: 'Taniec',
      anime: 'Anime',
      game: 'Gra',
      music: 'Muzyka',
      lgbt: 'LGBT',
      art: 'Sztuka / Współczesność',
      family: 'Rodzina / Miłość',
      beauty: 'Piękno',
      sexuality: 'Seksualność',
      masculinity: 'Męskość',
      tattoo_count_status: 'Liczba tatuaży',
      pierce_count_status: 'Liczba piercingów',
      one: 'Jeden',
      two: 'Dwa lub więcej',
      inc: 'Bardzo wiele',
      change_information: 'Zapisz informacje',
    },
    verification: {
      sms: 'Potwierdź przez SMS',
      email: 'Potwierdź przez Email',
      google: 'Zweryfikuj przez Google',
      send_code: 'Wyślij kod',
      code: 'Kod weryfikacyjny',
    },
    loyalty: {
      submit_button: 'Wyślij',
      bonus_instruction:
        'Wykonaj jeden z wymienionych punktów, dodaj dowody (zrzuty ekranu, zdjęcia itp.) i otrzymaj bonus',
      bonus_amount: 'VeanCoins!',
      review_option_1:
        'Zostaw szczegółową recenzję w profilu mistrza na stronie',
      review_option_2: 'Oceń studio lub mistrza na stronie',
      review_option_3: 'Oceń studio i dodaj recenzję na mapie',
      review_option_4: 'Udostępnij nasze studio w mediach społecznościowych',
      return:
        'Jeśli przesłane przez Ciebie zdjęcie nie pasuje do żadnej z kategorii, dla których przyznajemy bonusy, to otrzymane bonusy zostaną anulowane przy zakupie karty podarunkowej.',
      evidence_label: 'Dowody',
    },
    buy_certificate: {
      payment_type_title: 'Rodzaj zakupu',
      payment_type_subtitle: 'Co chcesz zrobić',
      buy_balance: 'Doładuj saldo',
      buy_certificate: 'Kup certyfikat',
      amount_title: 'Kwota',
      amount_subtitle: 'Wprowadź kwotę',
      payment_method_title: 'Metoda płatności',
      payment_method_subtitle: 'Wybierz metodę płatności',
      payment_title: 'Płatność',
      forming_payment: 'Tworzenie płatności...',
      pay: 'Zapłać',
      continue: 'Kontynuuj',
      back: 'Wstecz',
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Ponad 150 salonów',
      clients_count: 'Ponad 1 000 000 klientów',
      largest_company: 'Największa sieć studiów tatuażu na świecie',
    },
    master_block: {
      our_masters: 'Nasi tatuatorzy',
    },
    master_card: {
      works_count: 'Ilość prac: ',
    },
    no_admin_block: {
      leisure: 'Czas wolny',
      session_record: 'Zapis na sesje',
      about_company: 'O firmie',
      care: 'Pielęgnacja',
      quality_certificates: 'Certyfikat jakości',
      public_buyout_offer: 'Oferta publiczna',
      complaints_offers: 'Skargi i propozycje',
      loyalty_system: 'System lojalności',
      discount_page: 'Rabaty',
    },
    peculiarities_block: {
      about_studio: 'O tym studiu',
      clients: 'Klienci',
      sessions: 'Sesje',
      studio_hours: 'Czas pracy: ',
      studio_phone: 'Numer studia: ',
      wifi: 'Wi-fi: ',
      password: 'Hasło: ',
      from_to: 'c %{time_start} до %{time_end}}', //todo: dayte perevod
    },
    photos_block: {
      photos: 'Zdjęcie studia',
    },
    top_works: 'Top prac',
    care_important: 'Najważniejsze przy piercingu i tatuażu to pielęgnacja.',
    cabinet: 'Osobiste szafki',
    complete_agreemnent: 'Uzupełnij umowę',
    is_blanket: 'Jest koc, którym można się ogrzać',
    is_water: 'Są gorące napoje',
    is_hot_drinks: 'Są słodycze',
    is_treats: 'Są zimne napoje',
  },
  promotion: {
    submit: 'Wyślij',
    warning: 'Uwaga! Sprawdź poprawność wprowadzonych danych!',
    name: 'Imię / Nick',
    required: 'To pole jest wymagane!',
    max_value: 'Maksymalnie 100 znaków!',
    phone: 'Telefon',
    social_link: 'Link do mediów społecznościowych',
    wrong_format: 'Zły format!',
    terms: 'Zgadzam się z polityką prywatności',
    know_bonus: 'Wiem, że otrzymam punkty bonusowe',
    error: 'Wystąpił błąd',
    thanks: 'Dziękujemy!',
    bonus: 'Przyznano Ci %{gift_price} punktów',
    do_not_disturb:
      'Nie przeszkadzać (wyłącz SMS-y i połączenia od VeanTattoo)',
    get_promo: 'Chcę otrzymywać informacje o zniżkach i promocjach',
    first_time_veancoins_notification:
      'Aby użyć VeanCoins po raz pierwszy, należy skontaktować się z administratorem studia. VeanCoins i pieniądze mają różne zasady użytkowania',
  },
  another_studio_drawler: {
    another: 'Inne studia',
  },
  landing: {
    best_studio: 'Najlepsze tattoo studio w twoim mieście',
    vean_wide: 'Sieć VEAN TATTOO',
    studio_count: 'Ponad 150 tattoo studio',
    quality: 'Gwarancja jakości i bezpieczeństwa',
    get_price: 'Poznać cenę', //возможно есть
    contact_us: 'Skontaktuj się z nami',
    advantage: 'Zalety',
    top_works: 'Top prac',
    prices: 'Ceny', //долджен быиь
    studio_photo: 'Zdjęcia studia',
    prices_services: 'Ceny i usługi',
    about: 'O nas',
    loyalty_system: 'Program lojalnościowy', //должна быть
    why_vean: 'Dalczego VeAn',
    visit_count: 'Ludzi odwiedziło studio',
    description:
      'Sieć studiów tatuażu VeAn Tattoo jest zdecydowanie największa na świecie: mamy ponad 150 studiów w 14 krajach Europy. Przenieśliśmy tę niszę na zupełnie nowy, wyższy poziom standardów. Specjalizacja sieci jest dość szeroka, jednak głównym kierunkiem jest branża tatuażu i piercingu. Kluczowe zasady VeAn Tattoo: Wiedza i dostępność: Staramy się, aby każdy klient czuł się komfortowo na każdym etapie procesu, dlatego nasi specjaliści są gotowi odpowiedzieć na wszystkie Twoje pytania online. Reputacja i orientacja na klienta: Każdy klient to szansa na stworzenie niepowtarzalnego dzieła sztuki na swojej skórze. Cenimy Twój czas i dokładamy wszelkich starań, aby opuścił nasze studio z lepszym humorem i niesamowitym tatuażem. Dbamy o naszą reputację i cenimy każdego klienta sieci. Wartość i przystępność: Oferujemy idealny stosunek jakości do ceny. Nasze usługi są dostępne dla szerokiego grona odbiorców, jednocześnie nie rezygnując z jakości. Certyfikowany i wysokiej jakości sprzęt: Nasze materiały spełniają najwyższe standardy bezpieczeństwa i jakości. Gwarantujemy, że nasi klienci otrzymują tylko to, co najlepsze. Wysoko wykwalifikowani specjaliści i szeroki zakres usług: Studia VeAn Tattoo zatrudniają wyłącznie certyfikowanych artystów, którzy są cenieni i szanowani na całym świecie. Oferujemy wszystkie istniejące style i techniki tatuażu, bezpłatne opracowanie indywidualnego projektu według Państwa wymagań. Zapraszamy również do subskrypcji wszystkich naszych oficjalnych stron w sieciach społecznościowych. Dzięki temu będziesz na bieżąco z ofertami specjalnymi, promocjami, konkursami, dziełami artystów, najświeższymi wiadomościami i aktualnościami z VeAn Tattoo.',
    advantages: {},
    our_masters: 'Nasi tatuatorzy', //doljen bit
    from: 'z',
    no_price: 'Umowne',
    form: {
      get_price_consultation: 'Poznać cenę/ konsultacja',
      name: 'Imię',
      phone: 'telefon',
      place: 'Wasze miesjce',
      subscribe_news: 'Zapisz się do newslettera, aby otrzymać bonus',
    },
  },
  form_messages: {
    submit: 'Wysłać',
    warning: 'Uwaga! Sprawdź czy dane zostały wprowadzone poprawnie!',
    required: 'To pole jest wymagane!',
    length: 'Nie więcej niż %{length} liter!',
    file_length: 'Nie więcej niż %{length} plików!',
    wrong_format: 'Nieprawidłowy format!',
    error: 'Wystąpił błąd',
    thanks: 'Dziękujemy!',
    required_label: 'Wymagane',
    not_required_label: 'Opcjonalne',
  },
  training: {
    training: 'Szkolenia',
    tattoo_school: 'szkoła tatuaży',
    biggest_school: 'największa sieć salonów tatuażu',
    ne_otkladivaj: 'Nie<br/>odkładaj<br/>na później',
    learn: 'Dowiedz się, jak tatuować<br/>i zostać najlepszym artystą',
    reviews: 'Opinie absolwentów',
    record: 'zapisz się',
    video_text: 'W czym możemy pomóc,<br/>czego nauczymy?',
    ded: {
      consultation: 'konsultacje',
      get_more: 'dowiedz się więcej',
    },

    header_bar: {
      main: 'Strona główna',
      get: 'Czego się dowiez',
      program: 'Program szkoleniowy',
      about: 'Gwarancja',
      reviews: 'Recenzje',
    },

    our_course: {
      head: 'Nasze<br/>szkolenia',
      description_1:
        'to miejsce, w którym zawsze uzyskasz odpowiedź na swoje pytanie i nauczysz się czegoś nowego',
      description_2:
        'Nawet jeśli nie masz doświadczenia artystycznego, rezultat nie będzie zbyt długo oczekiwany',
      label: '[ Z nami jest nowocześnie, profesjonalnie i ekscytująco ]',
      description_3:
        'Uwielbiamy naszych uczniów i staramy się zrobić wszystko, aby proces nauki był jak najbardziej komfortowy',
    },

    banner: {
      we_teach: 'Nauczymy Cię robić tatuaże',
      good_make: 'Jesteśmy pro w tatuowaniu',
      our_masters:
        'Nasi mistrzowie są gotowi dzielić się wiedzą, sekretami oraz technikami',
      learn_too: 'i nauczymy Cię',
    },
    modal: {
      thanks: 'Dziękujemy!',
      error: 'Wystąpił błąd',
      complete: 'Wypełnij w formularz, skontaktujemy się z tobą',
      full_name: 'Imię nazwisko',
      phone: 'Numer telefonu',
      submit: 'Wyślij',
      cancel: 'Odwołać',
    },

    paragraph: {
      header_1: 'Na pewno dużo Cię nauczymy',
      content_1: 'Obserwuj nas na mediach społecznych, żeby być na bieżąco',
      header_2: 'Umiejętności rysowania nie są wymagane',
      content_2:
        'Nauczymy cię wszystkiego. Jeśli nie masz wykształcenia artystycznego  albo nie umiesz rysować - to żaden problem',
      header_3: 'Certyfikat',
      content_3:
        'Po ukończeniu kursów - orzymasz certyfikat międzynarodowy. Uznane przez Global Tattoo ACC',
      header_4: 'Zatrudniamy',
      content_4:
        'Czy gdybym ci powiedział, że w ciągu dwóch  miesięcy możesz zostać naszym tatuatorem / tatuatorką  - uwierzyłbyś mi ? Tak, to prawda.  najlepsi absolwenci mogą stać się częścią naszego zespołu i pracować w dowolnym kraju w Europie',
      header_5: 'Umowa',
      content_5: 'Pracujemy tylko oficjalnie, w ramach ustalonej umowy',
      no_sleep: {
        header: 'nie zaspać',
        rect_1: 'Jak?',
        rect_2: 'swojego życia',
        main_label: 'Zacznij naukę już teraz',
        label_1: '1000+ absolwentów',
        label_2: '100% ukończyło studia',
        label_3: '80% zatrudnionych osób',
        label_4: 'Ponad 300 partnerów w branży tatuażu',
      },
    },
    principies: {
      head: 'Gwarancje',
      label_1: '1. Kultura',
      label_2: '2. jakość',
      label_3: '3. metody',
      heading: 'Wszystko jest oparte na zasadach',
      description_1:
        '11 lat temu postanowiliśmy zacząć rozwijać kulturę w branży tatuażu, nie tak jak wszyscy a na swój własny sposób - w oparciu o naszej wiedzy i doświadczeniu',
      description_2:
        'Dziś wyszkoliliśmy ponad 1000+ doskonałych tatuatorów, z których 80% stało się częścią naszego zespołu. Możemy śmiało powiedzieć, że każdy może nauczyć się sztuki tatuażu',
      description_3:
        'Chodzi o stosowaną przez nas metodologię, którą stale ulepszamy wraz z zespołem, artystami i naszymi absolwentami, którzy regularnie przekazują nam informacje zwrotne',
    },
    packages: {
      head: 'Programy kursów',
      from: 'od',
      plus: 'zalety',
    },
    facilities: {
      head: 'Możliwości i korzyści',
      subhead: 'Wybierz nasze kursy i poczuj magię branży tatuażu',
      card: {
        head_1: 'Każdy nasz absolwent otrzymuje certyfikat międzynarodowego',
        label_1: '- twój bilet do świata możliwości',
        head_2: 'Rekomendacje i wsparcie od grupy profesjonalnych mistrzów',
        label_2: 'Twój sukces jest naszą dumą!',
        head_3:
          'Otrzymuj porady i wsparcie od naszej społeczności tatuatorskiej oraz ekspertów tej branży',
        label_3: 'Twoje nowe życie w świecie tatuaży zaczyna się teraz!',
      },
    },
    review: {
      head_1: 'Prace naszych mistrzów',
      head_2: 'Opinie',
      comment_1:
        'Szkolenie z tatuażu w tej szkole przekroczyło wszystkie moje oczekiwania! Nauczyciele są profesjonalistami w swojej dziedzinie, każdy etap szkolenia jest dokładnie omawiany, atmosfera jest świetna. Teraz mogę z pewnością realizować moje kreatywne pomysły na skórze klientów!',
      comment_2:
        'Dziękuję szkole za takie jakościowe szkolenie z tatuażu! Było trochę strasznie zabierać się za to, ale dzięki uważnym i cierpliwym nauczycielom poczułem się pewnie. Teraz moje prace zdobywają uznanie i podziw',
      comment_3:
        'To była niesamowita podróż do świata tatuażu! Szkoła dostarcza wszystkie niezbędne wiedzę i umiejętności, aby stać się prawdziwym profesjonalistą. Jestem wdzięczny za wsparcie i inspirację, które tu otrzymałem',
    },
  },
  questionary: {
    first: {
      heading: 'ZGODA NA PRZEPROWADZENIE ZABIEGU W STUDIO VEAN TATTOO',
      textbox:
        'Jeśli klient nie ukończył 18 lat, zgodę muszą wypełnić rodzice lub prawni opiekunowie (W Kolumbii Brytyjskiej, Kanada, osoba nieletnia uważana jest za osobę poniżej 19 roku życia. Jeśli masz poniżej 19 lat, wybierz DRUGĄ opcję)',
      has_18: 'TAK, mam ukonczone 18 lat',
      no_18: 'NIE mam ukonczone 18 lat',
    },
    second: {
      warning: 'Uwaga! W dniu sesji konieczne jest przybycie z opiekunami!',
      name: 'Imię i Nazwisko',
      birthdate: 'Data urodzenia',
      address: 'Adres',
      email: 'Email',
      sex: 'Płeć',
      heading: 'ZABIEG, KTÓRY BĘDZIE PRZEPROWADZONY',
      man: 'Mężczyzna',
      woman: 'Kobieta',
      other: 'Nie podano',
      parent_data: 'Dane jednego z rodziców:',
      child_data: 'Dane jednego NIELETNIEGO:',
      child_field_placeholder: 'Syn, córka, itp.',
      child_field_label: 'Dla kogo przeprowadza się procedurę',
      confirm:
        'Ja, będąc legalnym przedstawicielem nieletniego, wyrażam zgodę na przeprowadzenie następującej procedury mojemu dziecku.',
    },
    third: {
      heading: 'WSZYSTKO, CO MUSISZ WIEDZIEĆ O OKRESIE GOJENIA:',
    },
    fourth: {
      nothing: 'Żadne z powyższych',
      heading1:
        'JEŚLI MASZ JAKIEKOLWIEK Z NASTĘPUJĄCYCH CHORÓB, MUSIMY O TYM WIEDZIEĆ, PROSIMY O ZAZNACZENIE ODPOWIEDNICH PÓL:',
      heading2:
        'JEŚLI ISTNIEJĄ PRZECIWSKAZANIA, PROSIMY O PODANIE ODPOWIEDNICH INFORMACJI:',
    },
    fifth: {
      heading: 'PO PROCEDURZE ZABRONIONE JEST:',
    },
    sixth: {
      heading: 'SKĄD DOWIEDZIAŁEŚ SIĘ O NAS:',
      label:
        'Po 21 dniach jeden z naszych pracowników może się z Tobą skontaktować w ramach kontroli jakości świadczonych usług.',
    },
    seventh: {
      heading: 'OŚWIADCZENIE I ZGODA',
    },
    signature: {
      heading:
        'Ja, niżej podpisany (%{name}), wyrażam zgodę na przeprowadzenie dzisiaj zabiegu z moim udziałem\n' +
        '%{session_type} na moim ciele i zgadzam się z warunkami zawartymi w tym dokumencie.',
    },
    next: 'Dalej',
    submit: 'Wyślij',
    no_responsibility: 'Studio nie ponosi odpowiedzialności za tę sesję',
    acquaint: 'Zapoznałem się z dostarczonymi mi informacjami',
    acquaint_2: 'Rozumiem, że Vean Tattoo nie ponosi odpowiedzialności za proces gojenia.\n' +
      'W przypadku korekty tatuażu lub piercingu wymienionych powyżej, koszt korekty wyniesie 30% kosztu samej usługi.\n' +
      'Jeśli nie jesteś zadowolony z wyniku korekty, w takim przypadku środki za usługę nie podlegają zwrotowi, ponieważ wspomniane wcześniej obszary nie są korzystne do wykonania samej usługi.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Brak rozwiązania',
      in_check: 'W trakcie sprawdzania',
      problem_solved: 'Problem rozwiązany',
      wait: 'Oczekiwanie',
      small: 'Drobna sytuacja',
      unanswered_check: 'Sprawdzanie bez odpowiedzi',
    },
  },
  master_page: {
    card: {
      active: 'Aktywny',
      not_active: 'Nieaktywny',
      master_since: 'Mistrz od',
      services: 'Usługi',
      styles: 'Style',
      book: 'Zarezerwuj',
    },
  },
  stripe_warning: 'Uwaga! Minimalna kwota płatności przy użyciu stripe to 20€. Jeśli potrzebna jest mniejsza kwota, proszę skontaktować się ze studiem po szczegóły',
  check_in: {
    welcome_message: 'ŻYCZYMY UDANEJ SESJI!',
    session_started: 'TWOJA SESJA SIĘ ROZPOCZĘŁA',
    next_steps: 'Po zakończeniu sesji dostępne będą kolejne kroki',
    buy_healing_product: 'PROPONUJEMY ZAKUP ŚRODKA DO PIELĘGNACJI',
    skip: 'Pomiń',
    leave_review: 'Proszę, zostaw recenzję',
    your_impression: 'Jakie są twoje ogólne wrażenia?',
    add_photo: 'Dodaj zdjęcie pracy, aby otrzymać dodatkowe 1 Vean Coins',
    send: 'Wyślij',
    payment_success: 'Płatność zakończona sukcesem',
    get_invoice: 'Chcesz otrzymać fakturę?',
    invite_friend: 'Zaprosić przyjaciela',
    recommend_us: 'Polecaj nas swoim znajomym i zarabiaj razem z nimi',
    thank_employees: 'Proponujemy również podziękować naszym pracownikom',
    you_can_tip: 'Drogi Nikita, możesz również podziękować naszym pracownikom określoną kwotą napiwków',
    own_amount: 'Własna kwota',
    who_to_tip: 'Komu należy przekazać napiwek?',
    payment_type: 'Typ płatności',
    continue: 'Kontynuuj',
    summary: 'Podsumowanie',
    healing_agent: 'Środek leczniczy',
    tips: 'Napiwki',
    session_payment: 'Płatność za sesję',
    total: 'Razem',
    waiting: 'Oczekiwanie...',
    available_funds: 'Dostępne środki',
    refill_balance: 'Doładować konto',
    go_to_payment: 'Przejdź do płatności',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Napiwki',
    prepayment: 'Zaliczka',
    have_questions: 'Masz pytania?',
    title: 'Sesje',
    selectStatus: 'Wybierz status',
    selectParlor: 'Wybierz salon',
    loading: 'Ładowanie...',
    fromDate: 'Od daty',
    toDate: 'Do daty',
    search: 'Szukaj',
    all: 'Wszystkie',
    status: {
      100: 'Oczekujące',
      200: 'Nowy',
      300: 'W trakcie',
      400: 'Zakończone',
      500: 'Anulowane',
      600: 'Oczekuje na płatność',
    },
  },
  master: {
    rank: 'Ranga',
    works_count: 'Liczba prac',
    address: 'Adres',
  },
};
