export default {
  items_view: {
    header: 'Produkti',
    filter_form: {
      name_placeholder: 'Nosaukums',
      category_placeholder: 'Kategorija',
      parlor_placeholder: 'Salons',
      all_categories: 'Visas kategorijas',
      all_parlors: 'Visi saloni',
    },
    load_more_button: 'Ielādēt vairāk',
    not_found_message: 'Nekas nav atrasts',
  },
  item_view: {
    error_message: 'Kļūda datu ielādēšanā',
    available_in_parlor: 'Pieejams jūsu salonā',
    not_available_in_parlor: 'Nav pieejams jūsu salonā',
    similar_items_label: 'Līdzīgi produkti',
    log_in_to_buy: 'Pieslēgties, lai pirktu',
  },
  item_simple_card: {
    studio_price_label: 'Studijas cena',
    shop_price_label: 'Veikala cena',
  },
  student_candidate: {
    form: {
      who_studing: 'Students vai vietas īre',
      phone_number: 'Telefona numurs',
      first_name: 'Vārds',
      last_name: 'Uzvārds',
      social_link: 'Saites uz jebkuru sociālo tīklu',
      parlors: 'Salons',
      find_out: 'Kā jūs uzzinājāt par mums',
      start_date: 'Vēlamais sākuma datums',
      types: 'Sesiju tipi',
      work_permit_document: 'Darba atļauja',
      sanitary_book: 'Sanitārā grāmata',
      identity_proof: 'Identifikācijas dokuments',
      work_photos: 'Darba fotogrāfijas',
      end_date: 'Vēlamais beigu datums',
    },
  },
  user: {
    referral_links: {
      booking: 'Sesijas rezervācija',
      student_candidate: 'Students un īre',
      store: 'Klienta veikals',
      candidates_form: 'Ielūgt darbinieku',
    },
  },
  products: {
    want_add: 'Vēlos pievienot izvēlētos kopšanas produktus sesijas izmaksām',
    no_warranty: '*bez šīs opcijas mēs nevaram garantēt 100% veiksmīgu dzīšanu',
  },
  booking: {
    fast_booking: 'Ātra rezervācija',
    fast_booking_warning: 'Rezervācija nav galīga, mūsu menedžeris sazināsies ar jums, lai apstiprinātu sesijas laiku un datumu',
    phone_annotate: 'Lai palīdzētu mūsu pārdošanas komandai ātrāk reaģēt, lūdzu, norādiet savu vietējo kontakttālruni. Paldies!',
    increased_risk: 'Uzmanību! Augsta riska sesija',
    approximate_price: 'Aptuvenā cena',
    price_warning: 'Papildu preces nav iekļautas',
    type: {
      info: 'Ērta pieteikuma forma sesijai ar visu nepieciešamo informāciju',
      booking_button: 'PIETEIKTIES SESIJAI',
      unsure: ' Vēl neesat pārliecināts par izvēli? Mēs jums palīdzēsim!',
      consultant_help: 'Mūsu konsultanti ar prieku jums palīdzēs',
      consultation_button: 'KONSULTĀCIJA',
    },
    color: {
      black: 'Melns',
      colored: 'Krāsains',
    },
    controls: {
      next: 'TĀLĀK',
      back: 'Atpakaļ',
      no_select: 'Nav izvēlēts',
    },
    place_service: {
      select_studio: 'Izvēlieties studiju',
      service: 'Pakalpojums',
      size: 'Izmērs',
      location: 'Atrašanās vieta',
    },
    customer_info: {
      login_prompt: 'Lai turpinātu, lūdzu, piesakieties savā kontā',
      login_button: 'Pieslēgties',
      black_white_colored: 'Melnbalts vai krāsains?',
      select_style: 'Izvēlēties stilu',
      tell_more: 'Pastāstiet mums vairāk par savām tetovējuma priekšrocībām un dalieties piemēros (referencēs)',
      login_promo: 'Pieslēdzoties 10% atlaide',
      wish: 'Vēlējums',
    },
    date_time: {
      choose_time: 'Izvēlēties apmeklējuma laiku',
    },
    master_card: {
      master_unavailable: 'Meistars šajā laikā nav pieejams',
      master_guest_warning: 'UZMANĪBU! Meistars ir viesis',
      rank: 'Rangs:',
      works_count: 'Darbu skaits:',
      book_now: 'Rezervēt tagad',
      select_another_time: 'Izvēlēties citu laiku',
    },
    success: {
      session: 'SESIJA',
      become_mystery_shopper: 'Kļūt par noslēpumaino pircēju',
      add_to_calendar: 'Pievienot notikumu kalendāram',
      call_on_day: '*salona administrators jums zvanīs sesijas dienā',
      return_to_menu: 'Atgriezties izvēlnē',
      recommended_products: 'Ieteicamie produkti dzīšanai',
    },
  },
  new_landing: {
    count_block: {
      countries: 'Valstu skaits',
      studios: 'Studijas',
      awards: 'Apbalvojumi',
      masters: 'Meistari',
    },
    header_banner: {
      label_1: 'Mēs no mušas nepūšam ziloni',
      label_2: 'mēs veidojam tetovējumus!',
      book_now: 'Pierakstīties tagad',
      consultation: 'Konsultācija',
      prices: 'Cenas',
      top_works: 'Top darbi',
      advantages: 'Priekšrocības',
      change_parlor: 'Mainīt salonu',
    },
    course_card: {
      theory_part: 'Teorētiskā daļa:',
      practice_part: 'Praktiskā daļa:',
      advance: 'Papildu priekšrocības:',
    },
    helpful_links: {
      for_masters: 'Meistariem',
      tattoo_news: 'Tetovējumu ziņas',
      tattoo_news_description:
        'Esi kursā par svarīgākajiem notikumiem tetovējumu pasaulē',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'Mūsu meistari ir gatavi dalīties ar zināšanām, noslēpumiem, tehnikām',
      rent_space: 'Telpas noma',
      rent_space_description:
        'Uzziniet par sadarbības iespējām, īrējot darba vietu VEAN studijās',
      tattoo_promotions: 'Aktualios akcijos',
      tattoo_certificates: 'Dāvanu sertifikāti',
      consumables: 'Izmantojamie materiāli',
      equipment: 'Aprīkojums',
    },
    price_block: {
      title: 'Cenas',
      loyalty_program: {
        title: 'Lojalitātes programma',
      },
      actions: {
        book_now: 'Rezervēt tagad',
        calculate_cost: 'Aprēķināt izmaksas',
      },
      // другие вложенные переводы...
    },
    top_works: {
      title: 'Labākie darbi',
      from_the_world: 'no visas pasaules',
    },
    why_vean: {
      title: 'Kāpēc VEAN',
      individual_approach: 'Individuāla pieeja',
      trust: 'Uzticība',
      safety_guarantee: 'Drošības garantija',
      convenient_location: 'Ērta atrašanās vieta',
      visited_studio: 'Cilvēki apmeklēja šo studiju',
    },
  },
  mystery_shopper: {
    title: 'Noslēpumainais pircējs',
    description:
      'Noslēpumainais pircējs ir persona, kas pārbauda salonu darbību, izliekoties par parastu klientu. Viņš vērtē pakalpojuma līmeni salonā.',
    offer:
      'Ja jūs izlemsit kļūt par noslēpumaino pircēju šajā sesijā, mēs dāvināsim jums monētas.',
    instructionTitle: 'Norādījumi',
    instructionDetails:
      'Sesijas laikā jums būs jānovērtē salons un personāls dažādos kritērijos. Pēc sesijas jums saņems SMS ar saiti uz aptaujas anketu, kuru jāaizpilda godīgi.',
    question: 'Vai jūs vēlaties kļūt par noslēpumaino pircēju?',
    yesButton: 'Jā',
    noButton: 'Nē, paldies',
    successMessage: 'Jums būs jānovērtē studija šādos kritērijos:',
    criteria1: 'Konsultācija pa telefonu un sociālajos tīklos',
    criteria2: 'Konsultācija studijā, kad iegriežaties',
    criteria3: 'Studijas cenpolitika',
    criteria4: 'Ārpuse: Studijas fasāde, izkārtnes, atrašanās vieta u.c.',
    criteria5: 'Studijas interjers, iekārtojums',
    criteria6: 'Preču, suvenīru un dziedināšanas līdzekļu sortiments (izstāde)',
    criteria7: 'Atmosfēra: TV, mūzika, personāls',
    criteria8: 'Uzkodu pieejamība, izklaide, žurnāli un katalogi ar skicēm',
    criteria9: 'Saskarsme ar meistaru',
    criteria10: 'Saskarsme ar administratoru',
  },
  cookies: {
    text: `Mēs izmantojam sīkdatnes, lai uzlabotu jūsu pārlūkošanas pieredzi, 
      rādītu personalizētas reklāmas vai saturu 
      un analizētu mūsu datplūsmu. Noklikšķinot uz „Pieņemt“, 
      jūs piekrītat sīkdatņu izmantošanai.`,
    confirm: 'Pieņemt',
    more: 'Uzzināt vairāk',
    manage: 'Pārvaldīt',
    config: {
      necessary: 'Nepieciešamas',
      preferences: 'Izvēles',
      statistics: 'Statistiskās',
      marketing: 'Mārketinga',
    },
  },
  header_menu: {
    logout: 'Izeja',
    main: 'Sākums',
    about_us: 'Par mums',
    discount_page: 'Atlaides',
    loyalty_page: 'Lojalitātes programma',
    ai_page_link: 'Ģenerēt skici',
    fonts_page_link: 'Tatū burti',
    quality_certificate: 'Kvalitātes sertifikāti',
    complaints: 'Sūdzības un priekšlikumi',
    public_offer_link: 'Publiskā piedāvājums',
    confidence: 'Privātuma politika',
    work: 'Vēlos strādāt VEAN',
    dockuments_page_link: 'Dokumenti',
    login: 'Pieteikties',
    account: 'Personīgais konts',
  },
  post: {
    welcome: 'Labdien, jūs bijāt pie mums seansā',
    no_answer_questions: 'Jūs neatbildējāt uz sekojošajiem jautājumiem:',
    point: 'Lūdzu, novērtējiet apkalpošanas kvalitāti un saņemiet dāvanu',
    send: 'Sūtīt',
    error: 'Radās kļūda',
    not_found: 'Seanss nav atrasts!',
    not_call: 'Man nav jāatzvana',
    thank: 'Liels paldies par novērtējumu',
    bot: 'Pēc 21 dienas no nezināma numura Jums piezvanīs mūsu bots, lai precizētu informāciju par sniegto pakalpojumu un dzīšanu',
    certificate: 'Jums ir izveidots 100. akcijas sertifikāts',
    social_networks: 'Atstājiet, lūdzu, atsauksmes par mums sociālajos tīklos',
    month:
      'Izmantojiet to mēneša laikā, neaizmirstiet par to atgādināt administratoram',
    dissatisfied:
      'Vai esat neapmierināts ar saņemto pakalpojumu? Mēs apstrādāsim jūsu pieprasījumu pēc iespējas ātrāk.',
    scores: {
      administrator: 'Mans novērtējums studijas administratoram',
      master: 'Mans novērtējums studijas meistaram',
      recommendations:
        'Man mutiski un rakstiski tika sniegti aprūpes ieteikumi',
      care: 'Man piedāvāja iegādāties profesionālu aprūpi',
      sketch: 'Man piedāvāja pasūtīt individuālu skici/projektu',
      souvenir: 'Man piedāvāja iegādāties suvenīru produkciju',
      master_contact: 'Man vednis atstāja savu kontaktinformāciju',
      repeat_record: 'Vai vēlaties atkārtot ierakstu?',
    },
    scores_mystery_shopper: {
      instagram: 'Mūsu INSTAGRAM vērtējums',
      site: 'Vietnes vean-tattoo vērtējums.%{locale}',
      phone_consultation: 'Konsultācija telefoniski un sociālajos tīklos',
      consultation: 'Konsultācija studijā ierašanās laikā',
      price: 'Studijas cenu politika pēc jūsu domām',
      exterior: 'Eksterjers: Studijas fasāde, izkārtnes, lokācija utt.',
      interior: 'Studijas interjers, iekārtojums',
      assortment:
        'Suvenīru produkcijas un dzīšanas produkcijas sortiments (vitrīna)',
      atmosphere: 'Atmosfēra: TV, mūzika, kolektīvs',
      leisure: 'Cienasts, izklaide, žurnāli un skiču katalogi',
      master: 'Komunikācija ar meistaru',
      admin: 'Komunikācija ar administratoru',
      master_one_place: 'Meistars strādā tikai šajā studijā? (jā/nē)',
      master_contact:
        'Palūgt meistara personīgos kontaktdatus, iedeva? (jā/nē)',
      care: 'Jums piedāvāja iegādāties dzīšanas līdzekļus un mūsu suvenīru produkciju, uzdāvināja mazu dāvanu: magnēts, uzlīme vai 10% atlaides sertifikāts no pabeigtā darba summas nākošajam pakalpojumam? (jā/nē)',
      conclusion: 'Pārbaudes beigu vērtējums',
    },
    inputs: {
      cost: 'Es samaksāju par darbu',
      cost_other: 'Nopirku arī rotaslietas/kopšanas līdzekļus par summu',
      review: 'Mūsu trūkumi, kas novēroti darba laikā',
      photo: 'Фото салона',
    },
    inputs_mystery_shopper: {
      recommendation: 'Jūsu ieteikumi mums',
      why_vean: 'Kāpēc jūs izvēlējāties VEAN?',
    },
  },
  header: {
    online_appointment: 'Tiešsaistes ieraksts',
    post_session: 'Kvalitātes novērtējums',
    get_price: 'Uzzināt cenu',
    consultation: '(Konsultācija)',
    order_consultation: 'Pasūtīt konsultāciju',
  },
  index: {
    parlor: 'Studija',
    master: 'Meistars',
    session_type: 'Pakalpojuma tips',
    session_location: 'Pakalpojuma vieta',
    datetime: 'Datums un laiks',
    create_appointment: 'Pieteikties',
    bonus: '* %{customer_bonus} VeanCoins BONUS par patstāvīgu ierakstu',
    sketch: 'Sīktēls (nav obligāti)',
  },
  session_types: {
    tattoo: 'Tetovējums',
    tatouage: 'Permanents',
    piercing: 'Pīrsings',
    stroke: 'Dzēšana',
    training_tattoo: 'Tetovēšanas apmācība',
    training_piercing: 'Pīrsingu apmācība',
    training_tattouage: 'Tatūažas apmācība',
    training_udder: 'Noņemšanas apmācība',
  },
  price_form: {
    client_name: 'Nosaukums',
    client_phone: 'Tālrunis',
    client_comm: 'Jūsu pakalpojums',
    sketch_desc: 'Pievienojiet sīktēlu (* nav obligāti)',
    sketch_button: 'Atlasīt failu',
    send: 'Sūtīt',
    or: 'Vai',
    country: 'Valsts',
    city: 'Pilsēta',
    comment: 'Komentārs',
    val_error: 'Pārbaudiet ievadīto',
    ths_message: 'Paldies! Tuvākajā laikā ar jums sazināsies',
    go_back: 'Atpakaļ',
    file_size: 'Faila lielumam jābūt ne lielākam par 5 MB',
  },
  selectors: {
    salons: {
      placeholder: 'Meklēt pēc studijas nosaukuma',
    },
    vednis: {
      placeholder: 'Meklēt pēc vārda un pakalpojuma',
    },
    select_type: {
      select: 'Izvēlēties',
      next: 'Tālāk',
      no_price: 'Līgumu',
      ad: 'No',
      da: 'Līdz',
      selected: 'izvēlēts',
      info: 'Cena var būt atkarīga no izmēra un atrašanās vietas',
    },
    datetime: {
      select_date: 'Atlasiet datumu',
      no_active_times:
        'Atlasītajā datumā vairs nav brīvu laika lodziņu. Izvēlieties citu, lūdzu',
    },
  },
  verify: {
    verify_account: 'Verificēt kontu',
    type: 'Verifikācijas metode',
    check_code: 'Pārbaudīt kodu',
    status: 'Statuss',
    input_phone: 'Ievadiet savu tālruņa numuru',
    email_help: 'Jūs arī varat izmantot e-pastu verifikācijai',
    sms_sent: 'Jums ir nosūtīts SMS kods, ievadiet to zemāk',
    resend_sms_after:
      'Atkārtota iesniegšana ir pieejama pēc %{seconds} sekundēm',
    resend_sms: 'Sūtīt vēlreiz',
    buttons: {
      Back: 'Atpakaļ',
      Next: 'Tālāk',
      create_appointment: 'Pieteikties',
    },
  },
  success: {
    h1: 'Paldies! Šis ieraksts ir veiksmīgi izveidots',
    h2: 'Pēc ieraksta apstiprināšanas administrators Jums nosūtīs īsziņu',
    mystery: 'Kļūt par slepeno pircēju',
  },
  about: {
    header: 'Informācija par jums',
    birth_info:
      'Norādot dzimšanas datumu, mēs pārliecināmies, ka jums ir pieļaujamais vecums, kā arī tas vajadzīgs dzimšanas dienas atlaidei',
    inputs: {
      name: 'Vārds un uzvārds',
      find_out: 'Kā uzzināju',
      birth_date: 'Dzimšanas datums',
    },
    buttons: {
      skip: 'Izlaist',
      back: 'Atpakaļ',
      next: 'Tālāk',
    },
  },
  footer: {
    header:
      'Parakstoties uz mūsu sociālajiem tīkliem, lai būtu lietas kursā par visām ziņām',
  },
  subscribe_modal: {
    header: 'Abonējiet ziņas par bonusu',
    submit: 'Nosūtīt',
    wrong_data: 'Pārbaudiet ievadīto datu pareizību!',
    wrong_email: 'Nepareizi ievadīta e-pasta adrese!',
    thanks: 'Paldies par abonēšanu!',
    error: 'Radās kļūda!',
  },
  chat_bot: {
    call: 'Piezvanīt',
    online_record: 'Tiešsaistes pieraksts',
    online_consultation: 'Tiešsaistes konsultācija',
    need_help: 'Vai jums ir nepieciešama palīdzība ar skici?',
    order_sketch: 'Pasūtīt skici',
    dress_sketch: 'Pamēģiniet skici',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Problēmu situācijas',
      id: 'Numurs',
      status: 'Statuss',
    },
    reset_password: {
      title: 'Aizmirsi paroli',
      submit: 'sūtīt',
      forgot_password: 'Aizmirsi paroli',
      phone: 'Ievadiet telefona numuru',
      success_message: 'Pagaidu parole tika nosūtīta uz jūsu e-pastu/telefonu',
    },
    registration: {
      name: 'Vārds',
      surname: 'Uzvārds',
      registered: 'Reģistrācija',
      congratulations: 'Apsveicam!',
      successfully_registered: 'Jūs veiksmīgi reģistrējāties!',
      log_in: 'Ielogoties',
    },
    login_page: {
      log_in_page: 'Pieteikties savā personīgajā kontā',
      number_missing: 'Jums nav numura?',
      register: 'Reģistrējieties!',
      steps: {
        sign_up: 'Reģistrējieties lojalitātes programmā vai PIETEIKTIES',
        check_coins: 'Pārbaudiet savus VeanCoin, reģistrējoties lojalitātes programmā',
        get_coins: 'Saņemiet savus VeanCoin katras sesijas beigās',
        accumulate: 'Vāciet un krājiet VeanCoin savā profilā',
        book_session: 'Rezervējiet sesiju un nāciet uz Vean, lai iegūtu savu tetovējumu',
        exchange_coins: 'Apmainiet VeanCoin un saņemiet atlaides',
      },
    },
    find_customer: {
      continue: 'Turpināt',
      not_found: 'Klients nav atrasts!',
      select_type: 'Izvēlieties pieteikšanās veidu',
      no_password: 'Jūs neesat iestatījis paroli!',
      found: 'Atrasts!',
      check: 'Pārbaude...',
      invalid_phone_or_password: 'Nederīgs telefona numurs vai parole',
      error_login: 'Ielogojoties radās kļūda',
      phone: 'Tālrunis',
    },
    product_card: {
      availability: 'Наличие в студиях:',
      unavailable: 'Nav pieejams',
    },
    store_body: {
      full_points_products: 'Товары, которые можно купить за VeanCoins',
      half_points_products: 'Товары, которые можно купить со скидкой 50%',
      no_products: 'Нет товаров',
    },
    description_block: {
      loyalty_system: 'Система лояльности VEAN',
      show_more: 'Parādīt vairāk',
    },
    profile_card: {
      my_sketches: 'Mani skices',
      share_link: 'Piedalieties šim saitam',
      tips: 'Dzeramnauda',
      send_certificate: 'Sūtīt sertifikātu',
      vip: 'VIP',
      not_vip: 'Kļūt par VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Nauda',
      active_certificates: 'Aktivizētas monētas',
      balance: 'Bilance',
      sessions: 'Sesijas',
      points_alltime: 'Visu laiku nopelnītas monētas',
      available_certificates: 'Pieejamie sertifikāti',
      certificates: 'Naudas sertifikāti',
      bought_certificates: 'Aktivizētas VeanCoins',
      certificate_number: 'Sertifikāta numurs',
      available_for: 'Derīgs līdz:',
      no_active_certificates: 'Nav naudas sertifikātu',
      no_active_bought_certificates: 'Nav aktīvu VeanCoins',
      coming_sessions: 'Gaidāmās sesijas',
      complete_agreement: 'Aizpildīt līgumu',
      date: 'Datums:',
      you_was: 'Jūs bijāt %{sessions} sesijās',
      edit: 'Rediģēšana',
      verify_account_status: 'Konta statuss',
      security_percent: 'Drošība:',
      not_email: 'lai nosūtītu sertifikātu, jāaizpilda E-pasts',
      exist_application: 'ir lietotne',
      need_exist_application: 'nav lietotnes',
      need_confirm_account: 'Nepieciešams apstiprināt kontu',
      verified: 'Apstiprināts',
      choose_payment_method: 'Izvēlieties maksājuma veidu',
      parlor: 'Salons',
      sign_up_online: 'Pieteikties sesijai',
      mystery_shopper: 'Noslēpumainais pircējs',
      sign_up_secret_buyer:
        'Reģistrējieties pie mums uz sesiju un kļūstiet par noslēpumaino pircēju',
      pay: 'Maksāt',
      prepayment: 'Priekšapmaksa',
      questionnaire: 'Anketa',
      agreement: 'Līgums',
      fill_required_information: 'Jāaizpilda informācija',
      buy: 'Papildināt bilanci',
      archive: 'Arhīvs',
      support_service: 'Vean Tattoo Community klientu atbalsts',
      balance_buy_history: 'Pirkto punktu VeanCoins',
      balance_history: 'Iegūto punktu VeanCoins',
      buy_history: 'Pirkumu vēsture',
      сost: 'Izmaksas',
      payment_link: 'Maksājuma saite',
      attributes: 'Atribūti',
      invite_friend: 'Aicināt draugu',
      phone_or_email: 'Ievadiet tālruņa numuru vai e-pasta adresi',
      report_problem: 'Ziņot par problēmu',
      note: 'Ievadiet piezīmi',
      tooltip_coins:
        'jums ir jāaktivizē savas uzkrātās monētas izvēlētajam pakalpojumam vai preču iegādei, lai izvairītos no to izdegšanas viena gada laikā',
      tooltip_certificate:
        'jūsu nopirktu vai dāvinātu naudas sertifikātu skaits, ko var izmantot divu mēnešu laikā',
      tooltip_сonfirmed:
        'Rediģējot mainiet «ФИО или username» uz «Vārds un Uzvārds»',
      tooltip_missing:
        'Pievienojiet par sevi informāciju (vārds un uzvārds, dzimšanas datums, saite uz jūsu sociālajiem tīkliem, kā uzzinājāt par mums, telefona numurs un e-pasts identitātes apstiprināšanai)',
      tooltip_verified:
        'Jums ir jāapstiprina savs konts ar telefona numuru vai e-pastu, lai aizsargātu jūsu iegūtās un uzkrātās VeanCoins.',
      tooltip_vip:
        'VIP klients ir statuss, ko var iegūt katrs klients, kas iztērējis pakalpojumiem vairāk nekā 1500 eiro. VIP statuss sniedz noteiktas priekšrocības tā īpašniekam, piemēram, ātru prioritāro reģistrāciju sesijām ārpus rindas, piekļuvi unikālām precēm un pakalpojumiem, sasniedzot VIP statusu, klients nekavējoties saņem 1000 monētas uz savu kontu',
    },
    profile_edit: {
      additional_information: 'Papildus informācija par VeanCoins',
      parlors: 'Salons (kas ir tuvāk vai galvenais)',
      client_card: 'Klienta karte',
      full_name: 'Vārds un uzvārds vai lietotājvārds',
      birth_date: 'Dzimšanas datums (apsveikumam)',
      social_media_link: 'Saite uz sociālajiem tīkliem',
      find_out: 'Kā jūs uzzinājāt',
      or: 'vai',
      phone_placeholder: 'Tālrunis',
      save: 'Saglabāt',
      password_tooltip:
        'Izveidojiet paroli, lai nākotnē varētu piekļūt savam profilam bez SMS un e-pasta palīdzības. Tas arī aizsargā jūsu personīgos datus.',
      password: 'Parole',
      repeat_password: 'Atkārtot paroli',
      email_taken: 'Šis e-pasta adrese jau ir aizņemta!',
      phone_taken: 'Šis tālruņa numurs jau ir aizņemts!',
      simple_password: 'Pārāk vienkārša parole!',
      invalid_password: 'Nepareiza parole!',
      password_for_application: 'Parole lietojumprogrammai (nav obligāta)',
      congratulate: 'Apsveicam!',
      successfully_registered: 'Veiksmīgi reģistrēts!',
      need_confirm_account: 'Nepieciešama konta apstiprināšana',
      yes: 'Jā',
      no: 'Nē',
      phone_confirmed: 'Tālrunis apstiprināts',
      email_confirmed: 'E-pasts apstiprināts',
      change_password: 'Mainīt paroli',
      old_password: 'Vecā parole',
      new_password: 'Jaunā parole',
      confirm_password: 'Atkārtojiet paroli',
      invalid_phone_or_password: 'Nederīgs telefona numurs vai parole',
      error_login: 'Ielogojoties radās kļūda',
      years_old: 'Vecums',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Dzimums',
      man: 'Vīrietis',
      woman: 'Sieviete',
      gender_hidden: 'Vēlos to neuzrādīt',
      language: 'Valoda',
      preferred_style_status: 'Izlases stili',
      classic: 'Klasika / Realisms',
      freak: 'Friki / Trešais / Anime',
      fashion: 'Modes / Māksla / Minimalisms',
      rock: 'Rokmūzika / Smags metāls',
      pop: 'Popmūzika / Masa',
      accommodation_status: 'Dzīvesvieta',
      local: 'Vietējais',
      tourist: 'Tūrists',
      guest: 'Viesis',
      lifestyle_status: 'Dzīvesveids',
      extreme: 'Ekstrēms',
      dance: 'Deja',
      anime: 'Anime',
      game: 'Spēle',
      music: 'Mūzika',
      lgbt: 'LGBT',
      art: 'Māksla / Modernitāte',
      family: 'Ģimene / Mīlestība',
      beauty: 'Skaistums',
      sexuality: 'Seksualitāte',
      masculinity: 'Vīrišķība',
      tattoo_count_status: 'Tatū mākslas skaits',
      pierce_count_status: 'Piercingu skaits',
      one: 'Viena',
      two: 'Divas vai vairāk',
      inc: 'Ļoti daudz',
      change_information: 'Saglabāt informāciju',
    },
    verification: {
      sms: 'Apstiprināt caur SMS',
      email: 'Apstiprināt caur E-pasts',
      google: 'Pārbaudīt caur Google',
      send_code: 'Sūtīt kodu',
      code: 'Verifikācijas kods',
    },
    loyalty: {
      submit_button: 'Nosūtīt',
      bonus_instruction:
        'Izpildiet vienu no uzskaitītajiem punktiem, pievienojiet pierādījumus (ekrānšāviņus, foto utt.) un saņemiet bonusu',
      bonus_amount: 'VeanCoins!',
      review_option_1: 'Atstājiet detalizētu atsauksmi meistara profilā vietnē',
      review_option_2: 'Novērtējiet studiju vai meistaru vietnē',
      review_option_3: 'Novērtējiet studiju un atstājiet atsauksmi kartē',
      review_option_4: 'Dalieties ar mūsu studiju sociālajos tīklos',
      return:
        'Ja jūsu iesūtītā bilde nepieder nevienai no kategorijām, par kurām piešķiram bonusus, tad saņemtie bonusi tiks atcelti, iegādājoties dāvanu sertifikātu.',
      evidence_label: 'Pierādījumi',
    },
    buy_certificate: {
      payment_type_title: 'Pirkuma veids',
      payment_type_subtitle: 'Ko jūs vēlaties darīt',
      buy_balance: 'Papildināt bilanci',
      buy_certificate: 'Pirkt sertifikātu',
      amount_title: 'Summa',
      amount_subtitle: 'Ievadiet summu',
      payment_method_title: 'Maksājuma metode',
      payment_method_subtitle: 'Izvēlieties maksājuma metodi',
      payment_title: 'Maksājums',
      forming_payment: 'Maksājuma veidošana...',
      pay: 'Maksāt',
      continue: 'Turpināt',
      back: 'Atpakaļ',
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Vairāk nekā 150 saloni',
      clients_count: 'Vairāk nekā 1 000 000 klienti',
      largest_company: 'Lielākā tetovējumu studiju tīkls pasaulē',
    },
    master_block: {
      our_masters: 'Наши мастера',
    },
    master_card: {
      works_count: 'Количество работ: ',
    },
    no_admin_block: {
      leisure: 'Brīvais laiks',
      session_record: 'Запись на сеанс',
      about_company: 'О компании',
      care: 'Уход',
      quality_certificates: 'Сертификаты качества',
      public_buyout_offer: 'Публичная оферта',
      complaints_offers: 'Жалобы и предложения',
      loyalty_system: 'Система лояльности',
      discount_page: 'Atlaides',
    },
    peculiarities_block: {
      about_studio: 'Об этой студии',
      clients: 'Клиенты',
      sessions: 'Сеансы',
      studio_hours: 'Время работы: ',
      studio_phone: 'Номер студии: ',
      wifi: 'Wi-fi: ',
      password: 'Пароль: ',
      from_to: 'no %{time_start} līdz %{time_end}',
    },
    photos_block: {
      photos: 'Фото салона',
    },
    top_works: 'Darbu tops',
    care_important:
      'Svarīgākais pīrsingā un tetovēšanā – tā ir dzīšana un kopšana.',
    cabinet: 'Personīgā kabinets',
    complete_agreemnent: 'Aizpildiet vienošanos',
    is_blanket: 'Ir sega, ar kuru var sasildīties',
    is_water: 'Ir karstie dzērieni',
    is_hot_drinks: 'Ir saldumi',
    is_treats: 'Ir auksti dzērieni',
  },
  promotion: {
    submit: 'Nosūtīt',
    warning: 'Uzmanību! Pārbaudiet ievadīto datu pareizību!',
    name: 'Vārds / Niks',
    required: 'Šis lauks ir obligāts!',
    max_value: 'Ne vairāk kā 100 rakstzīmes!',
    phone: 'Telefons',
    social_link: 'Saite uz sociālo tīklu',
    wrong_format: 'Nepareizs formāts!',
    terms: 'Es esmu iepazinies ar personas datu apstrādes politiku',
    know_bonus: 'Man ir zināms, ka man tiks piešķirti bonusa punkti',
    error: 'Notikusi kļūda',
    thanks: 'Paldies!',
    bonus: 'Jums ir piešķirti %{gift_price} punkti',
    do_not_disturb: 'Nesatraukties (atslēgt SMS un zvanus no VeanTattoo)',
    get_promo: 'Es vēlos saņemt informāciju par atlaides un akcijām',
    first_time_veancoins_notification:
      'Lai pirmo reizi izmantotu VeanCoins, jāsazinās ar studijas administratoru. VeanCoins un nauda ir atšķirīgas lietošanas noteikumi',
  },
  another_studio_drawler: {
    another: 'Citas studijas',
  },
  landing: {
    best_studio: 'Labākā tavas pilsētas tetovēšanas studija',
    vean_wide: 'VEAN TATTOO TĪKLS',
    studio_count: 'Vairāk kā 150 tetovēšanas studijas',
    quality: 'Kvalitātes un drošības garantija',
    get_price: 'UZZINĀT CENU',
    contact_us: 'Sazināties ar mums',
    advantage: 'Priekšrocības',
    top_works: 'Darbu tops',
    prices: 'Cenas', //долджен быиь
    studio_photo: 'Studijas fotogrāfija',
    prices_services: 'Cenas un pakalpojumi',
    about: 'Par mums',
    loyalty_system: 'Lojalitātes programma', //должен быть
    why_vean: 'Kāpēc VEAN',
    visit_count: 'Cik cilvēki apmeklēja šo studiju',
    description:
      'VeAn Tattoo tetovēšanas studiju tīkls pašlaik ir lielākais pasaulē: mums ir vairāk kā 150 saloni 14 Eiropas valstīs. Mēs panācām to, ka šai nišai ir pilnībā jauns, augstāks standartu līmenis. Tīkla specializācija ir pietiekami plaša, taču pamata virziens ir tetovēšana un pīrsinga industrija. Svarīgākie kompānijas VeAn Tattoo principi: Eksperti un pieejamība: Mēs tiecamies uz to, lai katrs klients justos ērti katrā procesa posmā, tāpēc mūsu speciālisti ir gatavi atbildēt uz visiem jūsu jautājumiem tiešsaistē. Reputācija un orientācija uz klientu: Katrs klients – tā ir iespēja radīt unikālu mākslas darbu uz ādas. Mēs novērtējam jūsu laiku un tiecamies pie tām, lai klients aizietu no mūsu studijas pacilātā noskaņojumā un ar neticamu tetovējumu. Mēs rūpējamies par reputāciju un novērtējam katru tīkla klientu. Vērtīgums un pieejamība: Mēs piedāvājam ideālu cenas un kvalitātes attiecību. Mūsu pakalpojumu ir pieejami plašai auditorijai, turklāt, no tā necieš kvalitāte. Sertificēts un kvalitatīvs aprīkojums: Mūsu materiāli atbilst augstākajiem drošības un kvalitātes standartiem. Mēs garantējam, ka mūsu klienti saņem tikai labāko. Augsti kvalificēti speciālisti un plaša pakalpojumu izvēle: VeAn Tattoo studijās strādā tikai diplomēti meistari, kurus augsti novērtē un ciena visā pasaulē. Mēs piedāvājam visus pastāvošos stilus un tetovēšanas tehnikas, bezmaksas individuālas skices izstrādāšanu saskaņā ar jūsu prasībām. Mēs piedāvājam arī sekot visām mūsu oficiālajām lapām sociālajos tīklos. Tas ļaus jums uzzināt par speciālajiem piedāvājumiem, akcijām, konkursiem, meistaru darbiem, pēdējiem jaunumiem un kompānijas VeAn Tattoo atjauninājumiem.',
    advantages: {},
    our_masters: 'Mūsu meistari', //doljen bit
    from: 'No',
    no_price: 'Līgumu',
    form: {
      get_price_consultation: 'Uzzināt cenu / Konsultācija',
      name: 'Nosaukums',
      phone: 'Tālrunis',
      place: 'Pilsēta',
      subscribe_news: 'Pierakstīties uz jaunumiem par bonusu',
    },
  },
  form_messages: {
    submit: 'Nosūtīt',
    warning: 'Uzmanību! Pārbaudiet ievadīto datu pareizību!',
    required: 'Tas ir obligāts lauks!',
    length: 'Ne vairāk kā %{length} burti!',
    file_length: 'Ne vairāk kā %{length} faili!',
    wrong_format: 'Nepareizs formāts!',
    error: 'Radās kļūda',
    thanks: 'Paldies!',
    required_label: 'Obligāti',
    not_required_label: 'Nav obligāti',
  },
  training: {
    training: 'APMĀCĪBA',
    tattoo_school: 'TETOVĒJUMU SKOLA',
    biggest_school: 'lielākais tetovējumu salonu tīkls pasaulē',
    ne_otkladivaj: 'Neatliec<br/>sevi uz vēlāku laiku',
    learn:
      'Iemācies veidot tetovējumus<br/>un kļūsti par augstākās klases meistaru',
    reviews: 'Absolventu atsauksmes',
    record: 'Pieteikties',
    video_text: 'Kā jūs varat palīdzēt,<br/>ko jūs mācīsiet?',
    ded: {
      consultation: 'konsultācija',
      get_more: 'Uzziniet vairāk informācijas',
    },
    header_bar: {
      main: 'Galvenā',
      get: 'Ko jūs iegūsiet',
      program: 'Kursa programma',
      about: 'Par mums',
      reviews: 'Atsauksmes',
    },
    our_course: {
      head: 'Mūsu<br/>kursi',
      description_1:
        'šī ir vieta, kur vienmēr saņemsiet atbildi uz jautājumu un mācīsieties kaut ko jaunu',
      description_2:
        'Un pat ja jums nav mākslinieciskās izglītības - rezultāts neliks sevi ilgi gaidīt',
      label: '[ pie mums ir silti, mājīgi un aizraujoši ]',
      description_3:
        'Mēs mīlam mūsu studentus un cenšamies izdarīt visu iespējamo, lai mācīšanās process būtu pēc iespējas ērtāks',
    },
    banner: {
      we_teach: 'Mēs tev iemācīsim veikt tetovējumus',
      good_make: 'MĒS VEIDOJAM LIELISKUS TETOVĒJUMUS',
      our_masters:
        'MŪSU MEISTARI IR GATAVI DALĪTIES AR ZINĀŠANĀM, NOSLĒPUMIEM, TEHNIKĀM',
      learn_too: 'Un mēs iemācīsim arī tevi',
    },
    modal: {
      thanks: 'Paldies!',
      error: 'Radās kļūda!',
      complete: 'Aizpildiet formu un mēs jums zvanīsim',
      full_name: 'Pilnais vārds',
      phone: 'Telefona numurs',
      submit: 'Iesniegt',
      cancel: 'Atcelt',
    },
    paragraph: {
      header_1: 'Individuālas apmācības',
      content_1: 'Mēs neveicam masveida apmācību',
      header_2: 'spēja zīmēt',
      content_2:
        'Mēs jums visu iemācīsim/nav nepieciešama mākslinieciskā izglītība, tas nav problēma',
      header_3: 'Sertifikāts',
      content_3:
        'Pēc kursu pabeigšanas jūs saņemsiet sertifikātu ar starptautiskiem standartiem. Apstiprināts no Global Tattoo ACC',
      header_4: 'Nodarbinātība',
      content_4:
        'OMG, vai jūs nopietni? Jā, labākie absolventi var kļūt par mūsu komandas daļu un strādāt jebkurā Eiropas vietā',
      header_5: 'Līgums',
      content_5:
        'Mēs nestrādājam "uz goda vārda". Viss ir oficiāli un līguma veidā',
      no_sleep: {
        header: 'neizgulēt',
        rect_1: 'Kā?',
        rect_2: 'DZĪVE',
        main_label: 'Sāciet mācīties tūlīt',
        label_1: '1000+ absolventu',
        label_2: '100% pabeidza apmācību',
        label_3: '80% tika nodarbināti',
        label_4: '300+ partneri tetovējumu nozarē',
      },
    },
    principies: {
      head: 'Garantijas',
      label_1: '1. Kultūra',
      label_2: '2. Kvalitāte',
      label_3: '3. Metodes',
      heading: 'Viss ir balstīts uz principiem',
      description_1:
        'Pirms 11 gadiem mēs nolēmām attīstīt tetovējumu nozares kultūru, ne tāpat kā visi, bet savā veidā - balstoties uz zināšanām un pieredzi',
      description_2:
        'Šodien mēs esam apmācījuši 1000+ izcilu tetovējumu meistarus. 80% kļuva par mūsu komandas daļu. Mēs varam droši teikt, ka ikviens var iemācīties tetovējuma mākslu',
      description_3:
        'Viss ir atkarīgs no metodikas, ko mēs izmantojam, ko nepārtraukti uzlabojam kopā ar komandu, māksliniekiem un mūsu absolventiem, kuri regulāri sniedz atsauksmes',
    },
    packages: {
      head: 'Kursu programmas',
      from: 'no',
      plus: 'Priekšrocības',
    },
    facilities: {
      head: 'Iespējas un priekšrocības',
      subhead:
        'Izvēlieties mūsu kursus un izjūtiet tetovējumu industrijas burvību',
      card: {
        head_1:
          'Katrs mūsu absolvents saņem sertifikātu ar starptautiskiem standartiem',
        label_1: '- jūsu biļete uz iespēju pasauli',
        head_2: 'Ieteikums un atbalsts no profesionālu meistaru grupas',
        label_2: 'Jūsu panākumi ir mūsu lepnums!',
        head_3:
          'Tetovējumu kopiena, saņemiet padomus un atbalstu no mūsu ekspertiem',
        label_3: 'Jūsu jaunā dzīve tetovējumu pasaulē sākas tagad!',
      },
    },
    review: {
      head_1: 'Meistaru gadījumu izpēte',
      head_2: 'Atsauksmes',
      comment_1:
        'Tetovējumu apmācība šajā skolā pārsniedza visas manas gaidas! Skolotāji ir savas jomas profesionāļi, katrs apmācības posms tiek rūpīgi apspriests, atmosfēra ir lieliska. Tagad es varu ar pārliecību realizēt savas radošās idejas uz klientu ādas!',
      comment_2:
        'Paldies skolai par tik kvalitatīvu tetovējumu apmācību! Sākumā bija nedaudz biedējoši sākt ar to, bet pateicoties uzmanīgiem un pacietīgiem skolotājiem, es jutos pārliecināts. Tagad mani darbi saņem atzinību un apbrīnu!',
      comment_3:
        'Tas bija neticami ceļojums uz tetovējumu pasauli! Skola sniedz visas nepieciešamās zināšanas un prasmes, lai kļūtu par īstu profesionāli. Esmu pateicīgs par atbalstu un iedvesmu, ko šeit esmu saņēmis',
    },
  },
  questionary: {
    first: {
      heading: 'PIEZĪME PAR PROCEDŪRU VEAN TATTOO STUDIO',
      textbox:
        'Ja klientam ir mazāk par 18 gadiem, piekrišanu jāaizpilda vecākiem vai likumīgiem aizbildņiem (Britu Kolumbijā, Kanādā, par nepilngadīgu tiek uzskatīta persona, kas jaunāka par 19 gadiem. Ja jums ir mazāk par 19 gadiem, lūdzu, izvēlieties OTRO iespēju)',
      has_18: 'JĀ, man ir 18 vai vairāk gadu',
      no_18: 'NĒ, man ir mazāk par 18 gadiem',
    },
    second: {
      warning:
        'Uzmanību! Sesijas dienā ir nepieciešams ierasties ar aizbildņiem!',
      name: 'Pilns vārds',
      birthdate: 'Dzimšanas datums',
      address: 'Dzīvesvieta',
      email: 'E-pasts',
      sex: 'Dzimums',
      heading: 'VEICAMĀ PROCEDŪRA',
      man: 'Vīrietis',
      woman: 'Sieviete',
      other: 'Nav norādīts',
      parent_data: 'Dati par vienu no vecākiem:',
      child_data: 'Dati par VIENNOLEĶA personu:',
      child_field_placeholder: 'Dēls, meita, utt.',
      child_field_label: 'Kam tiek veikts process',
      confirm:
        'Es, esot vieno no nepilngadīgā bērna likumīgais pārstāvis, piekrītu turpmākai procedūrai, ko veic manam bērnam.',
    },
    third: {
      heading: 'VISS, KO JŪS VAJAG ZINĀT PAR ĀRSTĒŠANAS PERIODU:',
    },
    fourth: {
      nothing: 'Neviens no iepriekš minētajiem',
      heading1:
        'JA JUMS IR KĀDAS NO SEKOJOŠAJĀM SĀPĒM, MUMS JĀZINA, LŪDZU, ATZĪMĒJIE ATBILDES PUNKTI:',
      heading2: 'JA IR KONTRINDIKĀCIJAS, LŪDZU, SNIEGT ATBILSTOŠO INFORMĀCIJU:',
    },
    fifth: {
      heading: 'AIZLIEGTS PĒC PROCEDŪRAS:',
    },
    sixth: {
      heading: 'KUR JŪS PAR MUMS UZZINĀJĀT:',
      label:
        'Pēc 21 dienas viens no mūsu darbiniekiem varēs ar jums sazināties, kā daļa no sniegto pakalpojumu kvalitātes kontroles.',
    },
    seventh: {
      heading: 'IZVĒRTĒJUMS UN PIEKRIŠANA',
    },
    signature: {
      heading:
        'Es, zemakrakstītais (%{name}), ar šo piekrītu šodienas procedūrai ar manu dalību\n' +
        '%{session_type} uz manu ķermeni un piekrītu nosacījumiem, kas minēti šajā dokumentā.',
    },
    next: 'Nākamais',
    submit: 'Iesniegt',
    no_responsibility: 'Studija neuzņemas atbildību par šo sesiju',
    acquaint: 'Es esmu iepazinies ar man sniegto informāciju',
    acquaint_2: 'Es saprotu, ka Vean Tattoo nav atbildīgs par dziedināšanas procesu.\n' +
      'Gadījumā, ja tiek veikta iepriekš minētā tetovējuma vai pīrsinga korekcija, korekcijas izmaksas būs 30% no pašas pakalpojuma cenas.\n' +
      'Ja jūs neapmierina korekcijas rezultāts, šajā gadījumā līdzekļi par pakalpojumu netiek atgriezti, jo iepriekš minētās zonas nav labvēlīgas pašam pakalpojumam.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Nav risinājuma',
      in_check: 'Pārbaudē',
      problem_solved: 'Problēma atrisināta',
      wait: 'Gaidīšana',
      small: 'Neliela situācija',
      unanswered_check: 'Pārbaude bez atbildes',
    },
  },
  master_page: {
    card: {
      active: 'Aktīvs',
      not_active: 'Neaktīvs',
      master_since: 'Meistars kopš',
      services: 'Pakalpojumi',
      styles: 'Stili',
      book: 'Rezervēt',
    },
  },
  stripe_warning: 'Uzmanību! Minimālā maksājuma summa, izmantojot stripe, ir 20 €. Ja nepieciešama mazāka summa, lūdzu, sazinieties ar studiju, lai saņemtu informāciju',
  check_in: {
    welcome_message: 'VĒLAM JUMS PATĪKAMU SESIJU!',
    session_started: 'JŪSU SESIJA IR SĀKUSIES',
    next_steps: 'Pēc sesijas pabeigšanas jums būs pieejami citi soļi',
    buy_healing_product: 'IESAKĀM IEGĀDĀTIES ĀRSTĒŠANAS LĪDZEKLI',
    skip: 'Izlaist',
    leave_review: 'Lūdzu, atstājiet atsauksmi',
    your_impression: 'Kādi ir jūsu vispārējie iespaidi?',
    add_photo: 'Pievienojiet darba fotoattēlu, lai iegūtu papildu 1 Vean Coin',
    send: 'Sūtīt',
    payment_success: 'Maksājums bija veiksmīgs',
    get_invoice: 'Vai vēlaties saņemt rēķinu?',
    invite_friend: 'Uzaicināt draugu',
    recommend_us: 'Iesakiet mūs saviem draugiem un pelniet kopā ar viņiem',
    thank_employees: 'Mēs arī iesakām pateikties mūsu darbiniekiem',
    you_can_tip: 'Dārgais Nikita, jūs varat arī pateikties mūsu darbiniekiem ar noteiktu summu dzeramnaudas',
    own_amount: 'Pašu summa',
    who_to_tip: 'Kam vajadzētu piešķirt dzeramnaudu?',
    payment_type: 'Maksājuma veids',
    continue: 'Turpināt',
    summary: 'Kopsavilkums',
    healing_agent: 'Ārstniecības līdzeklis',
    tips: 'Dzeramnauda',
    session_payment: 'Sesijas maksājums',
    total: 'Kopā',
    waiting: 'Gaidīšana...',
    available_funds: 'Pieejamie līdzekļi',
    refill_balance: 'Papildināt bilanci',
    go_to_payment: 'Doties uz maksājumu',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Dzeramnauda',
    prepayment: 'Avanss',
    have_questions: 'Jautājumi?',
    title: 'Sesijas',
    selectStatus: 'Izvēlieties statusu',
    selectParlor: 'Izvēlieties salonu',
    loading: 'Ielāde...',
    fromDate: 'No datuma',
    toDate: 'Līdz datumam',
    search: 'Meklēt',
    all: 'Visi',
    status: {
      100: 'Gaidīšana',
      200: 'Jauns',
      300: 'Procesā',
      400: 'Pabeigts',
      500: 'Atcelts',
      600: 'Gaidām maksājumu',
    },
  },
  master: {
    rank: 'Rangs',
    works_count: 'Darbu skaits',
    address: 'Adrese',
  },
};
