import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { onError } from "@apollo/client/link/error";

const cache = new InMemoryCache();
const getApolloDomain = () => {
  const { hostname } = window.location;
  if (hostname === "localhost") return "http://localhost:8080/v1/graphql";
  const splited = hostname.split(".").slice(-2).pop();
  if (splited === "pl" || splited === "lv") {
    return `https://gq.vean-tattoo.${splited}/v1/graphql`;
  }
  return `https://gql.vean-tattoo.${splited}/v1/graphql`;
};

const httpLink = createHttpLink({
  uri: getApolloDomain(),
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      console.error(
        `GraphQL error:\nMessage: ${message}\nCode: ${extensions.code}\nPath: ${extensions.path}`,
      );
    });
  }
  if (networkError) {
    console.error(`Network error:\n${networkError}`);
  }
});

const apolloClient = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache,
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

export { apolloClient, apolloProvider, DefaultApolloClient };
