export const getAddr = () => {
  const addr = window.location.host.split('.').slice(-2);
  if (window.location.hostname === 'localhost') {
    return 'localhost:8000';
  }
  addr.unshift((addr?.includes('pl') || addr?.includes('lv')) ? 'cr' : 'crm');
  return addr.join('.');
};
export const getDomainLocale = (defaultLocale = 'com') => {
  const domainLocale = window.location.hostname.split('.').slice(-2).pop();
  return domainLocale === 'localhost' || domainLocale === 'com'
    ? defaultLocale
    : domainLocale;
};

export function getHomePageUrl(locale, localeList) {
  if (
    locale !== 'ru' &&
    locale !== 'uk' &&
    locale !== 'en' &&
    localeList.includes(locale)
  ) {
    return `https://vean-tattoo.${locale}`;
  }
  return 'https://vean-tattoo.com';
}

export const getProtocol = () => {
  const addr = window.location.hostname;
  if (addr === 'localhost') {
    return 'http';
  }
  return 'https';
};

export const getMainSiteDomain = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:8000'
  } else {
    return `vean-tattoo.${getDomainLocale()}`
  }
};

export const getCRMDomain = (defaultHostName = 'http://localhost:8000') => {
  const { hostname } = window.location;
  if (hostname === 'localhost') return defaultHostName;
  const splited = hostname.split('.').slice(-2).pop();
  if (splited === 'pl') {
    return `https://cr.vean-tattoo.${splited}`;
  }
  return `https://crm.vean-tattoo.${splited}`;
};

export const getConvertedImageUrl = (image) => {
  return `${getCRMDomain() + '/api/convert/' + getCRMDomain() + '/uploads/' + image}`;
};

export const getImageUrl = (image) => {
  return image ? `${getCRMDomain('https://cr.vean-tattoo.pl')}/uploads/${image}` : null;
};

export const getSelfUrl = () => window.location.origin;

export const getQRCode = (str) => `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${str}`;

export const APIURL = `${getProtocol()}://${getAddr()}/api`;
export const bitrixURL =
  'https://vean-tattoo.bitrix24.eu/rest/1/3ph50ndl6wi4h0rv/crm.lead.add/';
